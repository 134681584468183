import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ErrorElement from '.';

const ErrorElementContainer: React.FC = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const onClick = () => navigate(-1);
  let errorMessage = 'An error occurred';
  if (isRouteErrorResponse(error)) {
    if (error.status == 404) {
      errorMessage = 'Not Found';
    }
  }
  return <ErrorElement onClick={onClick} errorMessage={errorMessage} />;
};

export default ErrorElementContainer;
