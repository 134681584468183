import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { DaxDataType } from './types';

export const registerAutoComplete = (monaco: Monaco, daxData: DaxDataType) => {
  monaco.languages.registerCompletionItemProvider('dax', {
    // @ts-ignore
    provideCompletionItems: function (model, position) {
      return {
        suggestions: daxData.functions.map(
          (func: { name: any; snippet: any; description: any }) => ({
            label: func.name,
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: func.snippet,
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: func.description,
          }),
        ),
      };
    },
  });
};

export const registerErrorChecker = (monaco: Monaco) => {
  monaco.languages.registerHoverProvider('dax', {
    provideHover: function (model, position) {
      var word = model.getWordAtPosition(position);
      if (word) {
        var lineContent = model.getLineContent(position.lineNumber);
        if (word.word === 'SUM' && !lineContent.includes('(')) {
          return {
            contents: [{ value: '**Error**: SUM requires arguments' }],
          };
        }
      }
      return null;
    },
  });
};

export const registerDaxFormatter = (monaco: Monaco) => {
  monaco.languages.registerDocumentFormattingEditProvider('dax', {
    provideDocumentFormattingEdits: function (model) {
      var text = model.getValue();
      var lines = text.split('\n');
      var formattedLines = lines.map((line) => line.trim());
      var formattedText = formattedLines.join('\n');
      return [
        {
          range: model.getFullModelRange(),
          text: formattedText,
        },
      ];
    },
  });
};

export const registerDaxHover = (monaco: Monaco, daxData: DaxDataType) => {
  monaco.languages.registerHoverProvider('dax', {
    provideHover: async function (model, position) {
      const word = model.getWordAtPosition(position);
      if (!word) return;

      try {
        const response = await fetch(
          `/api/dax-function/${word.word.toLowerCase()}`,
        );
        if (!response.ok) return;

        const functionInfo = await response.json();

        const contents = [
          { value: `**${functionInfo.name}**` },
          { value: '**Syntax:**' },
          { value: '```dax\n' + functionInfo.syntax + '\n```' },
          { value: '**Parameters:**' },
          ...functionInfo.parameters.map(
            (p: { name: any; description: any }) => ({
              value: `- \`${p.name}\`: ${p.description}`,
            }),
          ),
          { value: '**Remarks:**' },
          ...functionInfo.remarks.map((r: any) => ({ value: `- ${r}` })),
          { value: '**Example:**' },
          { value: functionInfo.example },
          { value: '**Related Content:**' },
          ...functionInfo.related_content.map((rc: any) => ({
            value: `- ${rc}`,
          })),
        ];

        return {
          contents: contents,
          range: new monaco.Range(
            position.lineNumber,
            word.startColumn,
            position.lineNumber,
            word.endColumn,
          ),
        };
      } catch (error) {
        console.error('Error fetching function info:', error);
        return null;
      }
    },
  });
};

export const registerDaxLanguage = (monaco: Monaco, daxData: DaxDataType) => {
  monaco.languages.register({ id: 'dax' });
  monaco.languages.setMonarchTokensProvider('dax', {
    tokenizer: {
      root: [
        [
          /[a-zA-Z]\w*/,
          {
            cases: {
              '@keywords': 'keyword',
              '@functions': 'function',
              '@default': 'variable',
            },
          },
        ],
        [/".*?"/, 'string'],
        [/\d+(\.\d+)?/, 'number'],
        [/[=+\-*\/\(\)\[\]{}]/, 'operator'],
        [/--.*$/, 'comment'],
        [/\/\*/, 'comment', '@comment'],
      ],
      comment: [
        [/[^/*]+/, 'comment'],
        [/\*\//, 'comment', '@pop'],
        [/[/*]/, 'comment'],
      ],
    },
    keywords: daxData.keywords,
    functions: daxData.functions.map((f: { name: any }) => f.name),
  });

  // Add folding rules
  monaco.languages.registerFoldingRangeProvider('dax', {
    provideFoldingRanges: function (model, context, token) {
      const ranges = [];
      const lines = model.getLinesContent();
      let inMeasure = false;
      let measureStart = 0;

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.startsWith('MEASURE') && !inMeasure) {
          inMeasure = true;
          measureStart = i;
        } else if (line === '' && inMeasure) {
          ranges.push({
            start: measureStart + 1,
            end: i,
            kind: monaco.languages.FoldingRangeKind.Region,
          });
          inMeasure = false;
        }
      }

      if (inMeasure) {
        ranges.push({
          start: measureStart + 1,
          end: lines.length - 1,
          kind: monaco.languages.FoldingRangeKind.Region,
        });
      }

      return ranges;
    },
  });
};

export const registerDaxSnippets = (monaco: Monaco) => {
  monaco.languages.registerCompletionItemProvider('dax', {
    // @ts-ignore
    provideCompletionItems: function (model, position) {
      const snippets = [
        {
          label: 'YTD',
          insertText:
            'CALCULATE(\n\t${1:expression},\n\tDATESTYTD(${2:date_column})\n)',
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Year-to-date calculation',
          kind: monaco.languages.CompletionItemKind.Snippet,
        },
        {
          label: 'Previous Year',
          insertText:
            'CALCULATE(\n\t${1:expression},\n\tSAMEPERIODLASTYEAR(${2:date_column})\n)',
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Previous year calculation',
          kind: monaco.languages.CompletionItemKind.Snippet,
        },
        {
          label: 'Running Total',
          insertText:
            'CALCULATE(\n\t${1:expression},\n\tFILTER(\n\t\tALL(${2:date_column}),\n\t\t${2:date_column} <= MAX(${2:date_column})\n\t)\n)',
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Running total calculation',
          kind: monaco.languages.CompletionItemKind.Snippet,
        },
      ];

      return { suggestions: snippets };
    },
  });
};

export const registerDaxFormatterV2 = (monaco: Monaco) => {
  console.log('Formatting');
  monaco.languages.registerDocumentFormattingEditProvider('dax', {
    provideDocumentFormattingEdits(model, options) {
      const formatted = formatDaxV2(model.getValue(), options.tabSize);
      return [
        {
          range: model.getFullModelRange(),
          text: formatted,
        },
      ];
    },
  });
};

export const formatDaxV2 = (daxText: string, indentSize = 2) => {
  const indent = ' '.repeat(indentSize);

  // Split the DAX code by newline and semicolon or comma
  const lines = daxText
    .split(/[\n;]/)
    .map((line) => line.trim())
    .filter(Boolean);

  // Process the DAX expression for formatting
  let formatted = '';
  let currentIndent = '';

  lines.forEach((line, idx) => {
    // Handle closing parentheses and adjust indentation
    if (line.endsWith(')')) {
      currentIndent = currentIndent.slice(0, -indentSize); // Reduce indent level
    }

    formatted += `${currentIndent}${line}\n`;

    // If the line has an opening parenthesis, increase the indentation
    if (line.includes('(') && !line.endsWith(')')) {
      currentIndent += indent;
    }
  });

  return formatted;
};
