import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type ErrorElementProp = {
  errorMessage: string;
  onClick: () => void;
};
const ErrorElement: React.FC<ErrorElementProp> = ({
  errorMessage,
  onClick,
}) => {
  return (
    <div className="text-center pt-4">
      <h1>{errorMessage}</h1>
      <h2 className="underline" onClick={onClick}>
        Go back
      </h2>
    </div>
  );
};

export default ErrorElement;
