import { useState, useEffect } from 'react';
import { useAuth } from '../auth/authContext';
import { ISemanticModelMetadata } from '../models/SemanticModel';
import { getAllUserModels } from '../services/modelsService';

export const useGetSemanticModels = () => {
  const [userModels, setUserModels] = useState<ISemanticModelMetadata[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const loadData = async () => {
      // Most API ops do not explicitly require an auth token
      // If no token and user exceeded free limit they get an error and we can
      // show a popup and prompt signin and subscription
      const authToken = user ? await user.getIdToken() : null;
      const userModelsResult = await getAllUserModels(authToken);
      setUserModels(userModelsResult);
    };

    loadData();
  }, [user]);

  return userModels;
};
