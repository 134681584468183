import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { createContext, useContext } from 'react';
import { IPlaygroundExecutionResult } from '../models/ExecutionResult';
import { ISemanticModelMetadata } from '../models/SemanticModel';
import { PlaygroundDetails } from '../models/Playground';
export interface ICodePlaygroundContext {
  currentPlayground: PlaygroundDetails;
  codeInput: string;
  setCodeInput: React.Dispatch<React.SetStateAction<string>>;
  results: IPlaygroundExecutionResult | null;
  editorRef: React.MutableRefObject<editor.IStandaloneCodeEditor | null>;
  monacoRef: React.MutableRefObject<Monaco | null>;
}

export const CodePlaygroundContext = createContext<ICodePlaygroundContext>(
  {} as ICodePlaygroundContext,
);
export const useCodeEditorContext = () => useContext(CodePlaygroundContext)!;
