import React from 'react';
import { toggleFullScreen } from './utils';
import { Link } from 'react-router-dom';
import AuthComponentContainer from '../Auth/container';
import { useAuth } from '../../auth/authContext';

export const SideNavBar: React.FC = () => {
  const { daxSolverUser } = useAuth();
  return (
    <ul className="menu menu-lg bg-base-200 rounded-box fixed top-0 left-0 w-[120px] h-screen z-10 p-4 flex justify-between">
      <div>
        <li>
          <Link className="tooltip tooltip-right" data-tip="Home" to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
          </Link>
        </li>

        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Dax Playground"
            to="/playgrounds"
          >
            <svg
              className="w-10 h-10"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              xmlSpace="preserve"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  style={{ fill: '#45CCFF' }}
                  d="M500.989,331.041v13.29c0,20.623-16.714,37.337-37.326,37.337H48.337 c-20.612,0-37.326-16.714-37.326-37.337v-13.29H500.989z"
                ></path>{' '}
                <path
                  style={{ fill: '#EFEFEF' }}
                  d="M500.989,68.567v262.474H11.011V68.567c0-20.612,16.714-37.326,37.326-37.326h415.326 C484.275,31.24,500.989,47.955,500.989,68.567z"
                ></path>{' '}
                <path
                  style={{ fill: '#2C82C9' }}
                  d="M333.075,480.76c-51.384,0-102.767,0-154.151,0c12.022-32.827,17.426-66.269,17.734-99.097 c39.561,0,79.121,0,118.683,0C315.651,414.492,321.055,447.932,333.075,480.76z"
                ></path>{' '}
                <g>
                  {' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M463.663,20.23H48.337C21.683,20.23,0,41.914,0,68.567v275.764 c0,26.659,21.683,48.348,48.336,48.348h136.992c-1.233,26.663-6.004,52.508-14.226,77.07h-33.468 c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011h236.731c6.08,0,11.011-4.93,11.011-11.011 c0-6.081-4.931-11.011-11.011-11.011h-33.468c-8.222-24.564-12.993-50.409-14.226-77.07h136.991 c26.654,0,48.337-21.689,48.337-48.348V68.567C512,41.914,490.317,20.23,463.663,20.23z M22.022,68.567 c0-14.51,11.806-26.316,26.315-26.316h415.326c14.511,0,26.316,11.805,26.316,26.316V320.03H22.022V68.567z M317.757,469.75 H194.243c7.593-24.703,11.997-50.527,13.14-77.07h97.234C305.761,419.221,310.165,445.044,317.757,469.75z M489.979,344.332 c0,14.517-11.806,26.327-26.316,26.327H48.337c-14.51,0-26.315-11.81-26.315-26.327v-2.279H489.98v2.279H489.979z"
                  ></path>{' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M402.818,275.99H165.734c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011 h237.085c6.08,0,11.011-4.93,11.011-11.011C413.829,280.919,408.899,275.99,402.818,275.99z"
                  ></path>{' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M110.955,275.99h-1.773c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011 h1.772c6.08,0,11.011-4.93,11.011-11.011C121.965,280.919,117.035,275.99,110.955,275.99z"
                  ></path>{' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M409.973,158.442L335.275,94.58c-4.62-3.952-11.572-3.408-15.524,1.214 c-3.952,4.622-3.409,11.572,1.214,15.524l64.908,55.493l-64.908,55.493c-4.622,3.952-5.165,10.902-1.214,15.524 c2.178,2.547,5.265,3.856,8.374,3.856c2.531,0,5.075-0.869,7.149-2.641l74.698-63.862c2.448-2.092,3.856-5.15,3.856-8.369 S412.421,160.534,409.973,158.442z"
                  ></path>{' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M102.028,175.181l74.697,63.862c2.074,1.774,4.618,2.641,7.149,2.641 c3.107,0,6.197-1.308,8.374-3.856c3.952-4.622,3.409-11.572-1.214-15.524l-64.907-55.493l64.907-55.493 c4.622-3.952,5.165-10.902,1.214-15.524c-3.951-4.622-10.902-5.166-15.524-1.214l-74.697,63.862 c-2.448,2.092-3.856,5.15-3.856,8.369S99.58,173.089,102.028,175.181z"
                  ></path>{' '}
                  <path
                    style={{ fill: '#231F20' }}
                    d="M224.514,263.536c1.132,0.369,2.281,0.544,3.411,0.544c4.639,0,8.954-2.955,10.468-7.605 l56.155-172.509c1.883-5.782-1.279-11.996-7.061-13.878c-5.784-1.883-11.996,1.279-13.878,7.062l-56.155,172.509 C215.57,255.44,218.732,261.654,224.514,263.536z"
                  ></path>{' '}
                </g>{' '}
              </g>
            </svg>
          </Link>
        </li>
        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Manage Models"
            to="/manage-models"
          >
            <svg
              viewBox="-102.4 -102.4 1228.80 1228.80"
              className="icon w-10 h-10"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0">
                <path
                  transform="translate(-102.4, -102.4), scale(38.4)"
                  d="M16,31.43524600425735C19.09614350396777,31.178508005523877,20.517788400280548,27.50238770428351,22.989036166273127,25.619583020777497C25.375327370200424,23.801505401531205,29.26513268109411,23.46443272372609,30.202420265639894,20.614646078483787C31.139586940812467,17.765227053513794,28.65291821037813,15.016703465907923,27.36770539042363,12.306408617678384C26.26590175160704,9.982892481143978,25.10801575582648,7.792313855115458,23.26952670763097,5.994354869234401C21.140749881291192,3.91250758947517,18.96295598353332,1.4837067245812599,16.000000000000004,1.1893078247085214C12.93749949733759,0.885018195077111,9.854198948831298,2.342912642670048,7.599249016955726,4.43735822855742C5.485076370448397,6.401046869572194,4.8214475177410865,9.346872503454382,4.186801012226137,12.161658973353639C3.608258794884213,14.727611502026637,3.4426451840309396,17.31827047832062,4.122065556599251,19.859374851224143C4.8328300856822946,22.51770969939804,6.144197901734548,24.932441446496846,8.114849689029127,26.852978328217958C10.346550986013701,29.027926067578782,12.89442888916157,31.692765757750482,16,31.43524600425735"
                  fill="#7ed0ec"
                  strokeWidth="0"
                ></path>
              </g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M469.572424 567.833954c-10.976147-6.337475-27.610249-7.026433-37.11882-1.53836L122.346765 745.339871c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.463521z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M453.301677 881.79141c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 903.677641c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 925.563871c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
                  fill="#448FFF"
                ></path>
                <path
                  d="M116.004571 627.905475s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198-109.303742-63.563502zM907.273438 699.986581v127.395981l-80.225917 46.660424-41.34695-98.26153 121.572867-75.794875z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M469.572424 440.400221c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 617.901419c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521z"
                  fill="#6D8ACA"
                ></path>
                <path
                  d="M511.443171 825.155244s48.736737 34.849032 78.069382 18.64435c29.332645-16.209401 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.637198v52.856332z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M199.175078 592.008848v52.856332l312.423816 180.379723V772.388571l-312.423816-180.379723z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M373.3353 846.55071c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 710.934415c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729l207.782341 119.959005c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
                  fill="#6D8ACA"
                ></path>
                <path
                  d="M469.572424 567.833954c-10.976147-6.337475-27.610249-7.026433-37.11882-1.53836L122.346765 745.339871c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.463521z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M453.301677 881.79141c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 903.677641c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 925.563871c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
                  fill="#448FFF"
                ></path>
                <path
                  d="M116.004571 627.905475s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198-109.303742-63.563502zM907.273438 699.986581v127.395981l-80.225917 46.660424-41.34695-98.26153 121.572867-75.794875z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M469.572424 440.400221c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 617.901419c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521z"
                  fill="#6D8ACA"
                ></path>
                <path
                  d="M511.443171 825.155244s48.736737 34.849032 78.069382 18.64435c29.332645-16.209401 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.637198v52.856332z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M199.175078 592.008848v52.856332l312.423816 180.379723V772.388571l-312.423816-180.379723z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M373.3353 846.55071c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 710.934415c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729l207.782341 119.959005c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
                  fill="#6D8ACA"
                ></path>
                <path
                  d="M469.572424 350.259908c-10.976147-6.342194-27.610249-7.031152-37.11882-1.543079L122.346765 527.761106c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.458802z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M453.301677 664.212645c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 686.098876c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 707.985106c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
                  fill="#448FFF"
                ></path>
                <path
                  d="M116.004571 410.32671s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198L116.004571 410.32671zM907.273438 482.412535v127.391262l-80.225917 46.660424-41.34695-98.26153 121.572867-75.790156z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M469.572424 222.821456c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 400.322654c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521zM373.3353 628.971945c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 493.35565c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729L365.1433 635.163134c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
                  fill="#6D8ACA"
                ></path>
                <path
                  d="M511.443171 607.897364s48.736737 34.853751 78.069382 18.64435c29.332645-16.204682 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.641917v52.851613z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M199.175078 374.755687v52.856331l312.423816 180.379724v-52.856332L199.175078 374.755687z"
                  fill="#5D7BC1"
                ></path>
                <path
                  d="M469.572424 132.681143c-10.976147-6.342194-27.610249-7.031152-37.11882-1.543078L122.346765 310.182341c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.031152 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.458802z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M453.301677 446.63388c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 468.520111c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 490.406341c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
                  fill="#448FFF"
                ></path>
                <path
                  d="M116.004571 192.747945s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198L116.004571 192.747945zM907.273438 264.83377v127.391262l-80.225917 46.660424-41.34695-98.26153 121.572867-75.790156z"
                  fill="#4762AF"
                ></path>
                <path
                  d="M469.572424 5.242691C458.596276-1.094783 441.962175-1.783742 432.453604 3.704332L122.346765 182.743889c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217L469.572424 5.242691zM373.3353 411.39318c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 275.776885c-4.5207-2.614267-8.192 0.165161-8.192 6.191189 0 6.030747 3.6713 13.047742 8.192 15.65729l207.782341 119.963724c4.5207 2.609548 8.192-0.165161 8.192-6.195908z"
                  fill="#6D8ACA"
                ></path>
              </g>
            </svg>
          </Link>
        </li>
        {/* <li>
        <a
          className="tooltip tooltip-right"
          data-tip="Dax Jobs"
          href="/jobs"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            className="h-10 w-10"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 367.004 367.004"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {' '}
              <g>
                {' '}
                <path d="M160.937,197.203c24.125,0,80.846-29.033,80.846-98.603c0-9.705-0.236-19.081-1.037-27.94 c-1.313-17.427-4.883-32.872-13.768-44.71C215.578,9.838,195.629,0,160.937,0c-34.68,0-54.628,9.833-66.029,25.937 C86.012,37.778,82.44,53.231,81.127,70.669c-0.799,8.856-1.035,18.229-1.035,27.932C80.092,168.17,136.812,197.203,160.937,197.203 z M187.328,36.123c-2.355,5.951-3.535,8.926-5.891,14.877c-15.209-4.297-25.791-4.297-41,0c-2.355-5.951-3.533-8.926-5.891-14.877 C153.566,30.365,168.308,30.365,187.328,36.123z M92.119,83.517c0.932,2.037,4.889,9.869,11.014,10.604 c7.041,0.844,18.729-24.936,57.805-24.998c39.076,0.063,50.764,25.842,57.805,24.998c6.125-0.735,10.082-8.567,11.014-10.604 c0.189,4.992,0.259,10.046,0.259,15.084c0,34.006-15.015,55.076-27.612,66.763c-15.871,14.726-33.492,20.071-41.465,20.071 c-7.973,0-25.594-5.345-41.465-20.071c-12.598-11.687-27.612-32.757-27.612-66.763C91.86,93.562,91.93,88.509,92.119,83.517z"></path>{' '}
                <path d="M232.705,211.768c2.605-2.606,5.371-4.994,8.26-7.181c-11.223-6.117-22.109-11.933-29.153-15.697l-0.331-0.177 c-1.982-1.059-4.403-0.847-6.169,0.541c-9.085,7.132-19.035,11.938-29.574,14.283c-1.861,0.414-3.391,1.738-4.066,3.521 l-10.734,28.291l-10.734-28.291c-0.675-1.783-2.204-3.106-4.066-3.521c-10.539-2.346-20.489-7.152-29.573-14.283 c-1.769-1.388-4.188-1.601-6.17-0.541c-17.138,9.158-58.25,31.302-69.829,39.106c-19.621,13.219-28.199,61.053-29.72,70.508 c-0.15,0.938-0.064,1.898,0.253,2.793c0.703,1.982,18.709,48.548,149.84,48.548c5.933,0,11.627-0.098,17.105-0.28 c0.481-6.064,3.029-11.993,7.657-16.622l31.512-31.511C203.113,271.581,208.833,235.64,232.705,211.768z"></path>{' '}
                <path d="M336.396,219.546c-12.79-12.79-29.821-19.834-47.957-19.834c-18.135,0-35.166,7.044-47.955,19.834 c-21.512,21.511-26.052,54.982-11.04,81.396l1.317,2.319l-37.284,37.284c-6.042,6.043-6.041,15.876,0.002,21.92 c2.927,2.927,6.819,4.539,10.958,4.539c4.142,0,8.033-1.612,10.96-4.54l37.285-37.283l2.317,1.315 c10.126,5.748,21.698,8.787,33.464,8.787c18.127,0,35.149-7.04,47.933-19.824c12.791-12.79,19.835-29.822,19.835-47.957 C356.231,249.367,349.187,232.336,336.396,219.546z M319.779,298.842c-8.357,8.358-19.488,12.962-31.339,12.962 c-11.851,0-22.982-4.603-31.341-12.962c-17.281-17.281-17.281-45.399,0-62.68c8.359-8.359,19.49-12.962,31.341-12.962 c11.851,0,22.981,4.604,31.339,12.963c8.359,8.358,12.963,19.488,12.963,31.339S328.138,290.483,319.779,298.842z"></path>{' '}
              </g>{' '}
            </g>
          </svg>
        </a>
      </li> */}
        <li>
          <a
            href="https://model.daxsolver.com"
            data-tip="Model Playground"
            target="_blank"
            rel="noopener noreferrer"
            className="tooltip tooltip-right"
          >
            <svg
              fill="#000000"
              className="w-10 h-10"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="-5.5 -5.5 66.00 66.00"
              xmlSpace="preserve"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0">
                <path
                  transform="translate(-5.5, -5.5), scale(2.0625)"
                  d="M16,31.733256886713207C20.116828352725594,31.696150473513963,24.427106269803804,30.16880601777781,26.815206429257756,26.81520642925776C28.990499469268116,23.760451035096523,27.440096009894358,19.74266333845093,27.203661107458174,16C26.99227139792532,12.653791348728877,27.94518013395012,8.795659664800535,25.529944230574955,6.4700557694250485C23.130482706836105,4.159640849452783,19.32633480735177,5.3879865560536,16,5.212118833791465C12.333479201283543,5.01826502807358,8.170784422570174,2.9900162908377075,5.406080466811341,5.406080466811337C2.6043509867799384,7.854501106342395,3.727395423161917,12.27988290074446,3.655419905204326,15.999999999999998C3.5810668501744303,19.843002165841792,2.6153762032377865,23.987942178727476,4.988057849755458,27.011942150244536C7.5509221267432,30.27833126400856,11.848353233940161,31.770677130444458,16,31.733256886713207"
                  fill="#7ed0ec"
                  strokeWidth="0"
                ></path>
              </g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path d="M49,0c-3.309,0-6,2.691-6,6c0,1.035,0.263,2.009,0.726,2.86l-9.829,9.829C32.542,17.634,30.846,17,29,17 s-3.542,0.634-4.898,1.688l-7.669-7.669C16.785,10.424,17,9.74,17,9c0-2.206-1.794-4-4-4S9,6.794,9,9s1.794,4,4,4 c0.74,0,1.424-0.215,2.019-0.567l7.669,7.669C21.634,21.458,21,23.154,21,25s0.634,3.542,1.688,4.897L10.024,42.562 C8.958,41.595,7.549,41,6,41c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6c0-1.035-0.263-2.009-0.726-2.86l12.829-12.829 c1.106,0.86,2.44,1.436,3.898,1.619v10.16c-2.833,0.478-5,2.942-5,5.91c0,3.309,2.691,6,6,6s6-2.691,6-6c0-2.967-2.167-5.431-5-5.91 v-10.16c1.458-0.183,2.792-0.759,3.898-1.619l7.669,7.669C41.215,39.576,41,40.26,41,41c0,2.206,1.794,4,4,4s4-1.794,4-4 s-1.794-4-4-4c-0.74,0-1.424,0.215-2.019,0.567l-7.669-7.669C36.366,28.542,37,26.846,37,25s-0.634-3.542-1.688-4.897l9.665-9.665 C46.042,11.405,47.451,12,49,12c3.309,0,6-2.691,6-6S52.309,0,49,0z M11,9c0-1.103,0.897-2,2-2s2,0.897,2,2s-0.897,2-2,2 S11,10.103,11,9z M6,51c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S8.206,51,6,51z M33,49c0,2.206-1.794,4-4,4s-4-1.794-4-4 s1.794-4,4-4S33,46.794,33,49z M29,31c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S32.309,31,29,31z M47,41c0,1.103-0.897,2-2,2 s-2-0.897-2-2s0.897-2,2-2S47,39.897,47,41z M49,10c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S51.206,10,49,10z"></path>{' '}
              </g>
            </svg>
          </a>
        </li>

        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Pricing"
            to="/pricing"
          >
            <svg
              viewBox="-4.89 -4.89 58.67 58.67"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              className="w-10 h-10"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0">
                <path
                  transform="translate(-4.89, -4.89), scale(1.8334375)"
                  d="M16,30.881296760941808C18.701197215564928,30.4380362510261,20.84171879404674,28.457655165797426,22.73636354664056,26.481986281977278C24.325893775640186,24.82448008772804,24.77811975035826,22.52227739853826,25.92450671975942,20.532370353155134C27.226169931839102,18.272933648473796,30.749029782145147,16.47253411336829,29.928775241151726,13.997344458387783C29.04629415379646,11.334380769711018,24.891240895152173,11.865711510364633,22.54353538356145,10.32999431387394C21.041478657038716,9.3474458921758,20.19399833306264,7.725732659026195,18.737548183098173,6.676767086978103C16.628003011555723,5.1574289498765316,14.72811218264476,2.9988761221860862,12.133708853700572,2.8326215812175324C9.25254681027268,2.6479909697616564,6.16275811127618,3.660883077722982,4.162526248253785,5.742770605262864C2.1359889468249817,7.852037440740586,1.7889171291527168,11.003949694407865,1.4875204040383618,13.913420442055774C1.1955929186245244,16.731481849072424,1.3814266503728514,19.563735381768005,2.459374080939117,22.183796657271362C3.565636853963794,24.87268126327696,5.228939672747748,27.382104846402534,7.6753089292756105,28.953472151684608C10.121385186462575,30.52465125578506,13.131155724500971,31.35206778391597,16,30.881296760941808"
                  fill="#7ed0ec"
                  strokeWidth="0"
                ></path>
              </g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <g
                  id="red_dimond_premium"
                  data-name="red dimond premium"
                  transform="translate(-229.078 -417.619)"
                >
                  {' '}
                  <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M240.523,432.087l-7.982,9.977L250.5,466.01l17.959-23.946-7.982-9.977Z"
                    fill="#e23a44"
                    fillRule="evenodd"
                  ></path>{' '}
                  <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M238.527,421.328l2.993,5.184m-11.759,3.58,5.186,2.993m36.29-2.993-5.184,2.993m-3.58-11.757-2.993,5.184m-8.98-8.393v5.986m17.959,17.96H232.541m16.273-9.978-1.842,9.978L250.5,466.01l3.528-23.945-1.842-9.978Zm-3.668,0-4.671,9.978L250.5,466.01l10.025-23.945-4.671-9.978Zm17.327,17.96,5.986-7.982-7.982-9.978H240.523l-7.982,9.978,5.986,7.982m2.993,3.991,8.98,11.972,8.98-11.972"
                    fill="none"
                    stroke="#0f0e0b"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  ></path>{' '}
                </g>{' '}
              </g>
            </svg>
          </Link>
        </li>
        <li>
          <button
            onClick={toggleFullScreen}
            className="tooltip tooltip-right"
            data-tip="Toggle Fullscreen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 3H5a2 2 0 00-2 2v3m0 8v3a2 2 0 002 2h3m8-18h3a2 2 0 012 2v3m0 8v3a2 2 0 01-2 2h-3"
              />
            </svg>
          </button>
        </li>
        {daxSolverUser && (
          <li>
            <button className="flex flex-col">
              <svg
                viewBox="0 0 20 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="#ffffff"
                className="w-10 h-10"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {' '}
                  <title>profile [#1339]</title>{' '}
                  <desc>Created with Sketch.</desc> <defs> </defs>{' '}
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    {' '}
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-260.000000, -2159.000000)"
                      fill="#ffffff"
                    >
                      {' '}
                      <g
                        id="icons"
                        transform="translate(56.000000, 160.000000)"
                      >
                        {' '}
                        <path
                          d="M218,2005 C218,2002.794 216.206,2001 214,2001 C211.794,2001 210,2002.794 210,2005 C210,2007.206 211.794,2009 214,2009 C216.206,2009 218,2007.206 218,2005 L218,2005 Z M224,2019 L219,2019 L219,2017 L221.784,2017 C220.958,2013.214 217.785,2011 214,2011 C210.215,2011 207.042,2013.214 206.216,2017 L209,2017 L209,2019 L204,2019 C204,2014.445 206.583,2011.048 210.242,2009.673 C208.876,2008.574 208,2006.89 208,2005 C208,2001.686 210.686,1999 214,1999 C217.314,1999 220,2001.686 220,2005 C220,2006.89 219.124,2008.574 217.758,2009.673 C221.417,2011.048 224,2014.445 224,2019 L224,2019 Z M216.414,2013.757 L217.828,2015.172 L214,2019 L211.172,2016.172 L212.586,2014.757 L214,2016.172 L216.414,2013.757 Z"
                          id="profile-[#1339]"
                        >
                          {' '}
                        </path>{' '}
                      </g>{' '}
                    </g>{' '}
                  </g>{' '}
                </g>
              </svg>
              <span className="badge badge-primary uppercase text-white">
                {daxSolverUser.subscription_plan.slice(0, 3)}{' '}
                {/* Temporary measure to show current plan. Figure out a better label. */}
              </span>
            </button>
          </li>
        )}
      </div>
      <div>
        <li>
          <AuthComponentContainer />
        </li>
      </div>
    </ul>
  );
};
