import React from 'react';
import { useAuth } from '../../auth/authContext';
import { WhyWeBuiltThis } from '../WhyWeBuiltThis';

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex justify-between items-center mb-4 pl-8">
      <h1 className="text-3xl font-bold text-primary">Dax Solver</h1>
      <WhyWeBuiltThis />
    </div>
  );
};

export default Header;
