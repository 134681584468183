import { useNavigate } from 'react-router-dom';
import { DashboardTopCourses } from '.';
import { useGetAllCourses } from '../../hooks/useGetCourses';

export const DashboardTopCoursesContainer = () => {
  const courses = useGetAllCourses();
  const navigate = useNavigate();
  const onSeeMoreClick = () => navigate('/courses');
  return (
    <DashboardTopCourses courses={courses} onSeeMoreClick={onSeeMoreClick} />
  );
};
