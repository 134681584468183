import { IExecResult } from '../models/ExecutionResult';
import { IQuestion, IQuestionFilters } from '../models/Question';
import { ITopic } from '../models/Topic';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || 'https://api.daxsolver.com';

export const fetchQuestions = async (
  authToken?: string | null,
  filters?: IQuestionFilters
): Promise<IQuestion[]> => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    if (authToken) {
      headers.set('Authorization', `${authToken}`);
    } else {
      console.log('Requesting questions without auth token');
    }

    // Construct query params for url.
    const searchParams = new URLSearchParams();
    Object.entries(filters || {}).forEach(([key, value]) => {
      value.forEach((v) => {
        searchParams.append(key, v);
      });
    }
    );
    const queryString = searchParams.toString();

    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/problems?${queryString}`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch questions');
      return [];
    }
    const resp_data = await response.json();
    const data: IQuestion[] = resp_data.results;
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchTopics = async (authToken?: string | null) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    if (authToken) {
      headers.set('Authorization', `${authToken}`);
    } else {
      console.log('Requesting questions without auth token');
    }

    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/topics`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch topics');
      return [];
    }
    const resp_data = await response.json();
    const data: ITopic[] = resp_data.results;
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const fetchQuestion = async (
  questionId: string,
  authToken?: string | null,
): Promise<IQuestion | null> => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    if (authToken) {
      headers.set('Authorization', `${authToken}`);
    } else {
      console.log('Requesting questions without auth token');
    }

    const response = await fetch(
      `${BASE_API_URL}/api/v1/assessment/problems/${questionId}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch question');
      return null;
    }
    const data: IQuestion = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const evaluateSolution = async (
  questionId: string,
  code: string,
  submit: boolean,
  authToken?: string | null,
) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    if (authToken) {
      headers.set('Authorization', `${authToken}`);
    } else {
      console.log('Requesting questions without auth token');
    }

    const payload = {
      questionId: questionId,
      code: code,
      submit: submit,
    };
    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/evaluate`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      console.error('Failed to evaluate solution');
      const error_result: IExecResult = {
        correct: false,
        error: 'Oops! Something went wrong',
        code_evaluation_result: [],
      };
      return error_result;
    }
    const respData = await response.json();
    const data: IExecResult = respData.results;
    return data;
  } catch (e) {
    console.error(e);
    const error_result: IExecResult = {
      correct: false,
      error: 'Oops! Something went wrong',
      code_evaluation_result: [],
    };
    return error_result;
  }
};
