import { ISemanticModelMetadata, ISemanticModelUploadPayload } from '../models/SemanticModel';
import { BASE_API_URL } from './utils';

// If auth token is absent, only returns public models provided by us.
// If auth token is present, returns user-owned models.
export const getAllUserModels = async (
  authToken?: string | null,
): Promise<ISemanticModelMetadata[]> => {
  try {      
    const headers = new Headers();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/models`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch models');
      return [];
    }
    const models: ISemanticModelMetadata[] = await response.json();
    return models;
  } catch (e) {
    console.error(e);
    return [];
  }
  // function implementation here, stub above for now.
};

// TODO
export const uploadNewModel = async (authToken: string, modelDetails: ISemanticModelUploadPayload): Promise<boolean> => {
  // Uploading to blob storage
  const formData = new FormData();
  formData.append('file', modelDetails.file);
  formData.append('model_name', modelDetails.modelName);
  formData.append('model_description', modelDetails.modelDescription || '');
  formData.append('public', modelDetails.public.toString());

  try {
    const headers = new Headers({
      Authorization: authToken,
    });
    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/upload`, {
      method: 'POST',
      headers: headers,
      body: formData,
    });
    if (!response.ok) {
      console.error('Failed to upload file');
      return false;
    }
    console.log('File uploaded successfully');
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// TODO: can return a success signal we ignore rather than Promise<void>
export const deleteUserModel = async (modelId: string): Promise<void> => {
  console.log(`Model with modelId ${modelId} deleted`);
};


interface IUploadDetails {
  url: string;
  fields: { [key: string]: string };
}
// This function will submit the filename, and filesize so we can generate
// a presigned URL with a max file size.
export const getUploadDetails = async (authToken: string, file: File, fileName: string, modelName: string) => {
  // Calculate the file size
  const fileSize = file.size;
  console.log(`File size: ${fileSize}`);
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: authToken,
    });
    const payload = {
      fileName: fileName,
      fileSize: fileSize,
      modelName: modelName,
    }

    const response = await fetch(
      `${BASE_API_URL}/api/v1/assessment/upload-url`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch upload URL');
      return null;
    }
    const respData: IUploadDetails = await response.json();
    return respData;
  }
  catch (e) {
    console.error(e);
    return null;
  }
}