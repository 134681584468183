import { useState, useCallback } from 'react';

export const useCopyToClipboard = (withToast: JSX.Element) => {
  const [isCopied, setIsCopied] = useState(false);
  const [toastElement, setToastElement] = useState<JSX.Element | null>(null);

  const copyToClipboard = useCallback(
    (text: string) => {
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setIsCopied(true);
            if (withToast) {
              setToastElement(withToast);
              setTimeout(() => {
                setToastElement(null);
              }, 3000); // Clears toastElement after 3 seconds
            }
          })
          .catch((err) =>
            console.error('Failed to copy text to clipboard:', err),
          );
      } else {
        console.error('Clipboard API not supported');
      }
    },
    [withToast],
  );

  return { copyToClipboard, isCopied, toastElement };
};

export default useCopyToClipboard;
