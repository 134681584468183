import React from 'react';
import { Outlet } from 'react-router-dom';
import { SideNavBar } from '../components/SideNavBar';

const DashboardBase: React.FC = () => {
  return (
    <div className="flex min-h-screen text-white bg-base-200">
      <SideNavBar />
      <div className="flex-1 ml-[120px]">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardBase;
