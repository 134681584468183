import { createContext, useContext } from 'react';
import { IQuestion } from '../models/Question';
import { ITopic } from '../models/Topic';

interface IDashboardContext {
  questions: IQuestion[];
  topics: ITopic[];
}

export const DashboardContext = createContext<IDashboardContext>({
  questions: [],
  topics: [],
});
export const useDashboardContext = () => useContext(DashboardContext)!;
