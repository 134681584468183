import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { auth } from '../config/firebaseConfig';
import { onAuthStateChanged, User } from 'firebase/auth';
import { IDaxSolverUser } from '../models/DaxSolverUser';
import { getDaxSolverUser } from '../services/accountsService';

interface IAuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  daxSolverUser: IDaxSolverUser | null;
  refreshDaxSolverUser: (firebaseUser: User) => Promise<void>; // Enable refreshing user profile data into the auth context e.g. after a user updates their profile.
}
interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  daxSolverUser: null,
  refreshDaxSolverUser: async () => {},
});

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [daxSolverUser, setDaxSolverUser] = useState<IDaxSolverUser | null>(
    null,
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [firebaseInitializing, setFirebaseInitializing] = useState<boolean>(true);

  // Helper to fetch user profile data from the backend.
  const refreshDaxSolverUser = async (firebaseUser: User) => {
    if (!firebaseUser) return;

    const authToken = await firebaseUser.getIdToken();
    const userObj = await getDaxSolverUser(authToken);
    if (!userObj) {
      console.error('Failed to fetch user profile data');
      return;
    }
    setDaxSolverUser(userObj);
  };

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed, user: ', user);
      if (user) {
        console.log('User signed in: ', user);
        setUser(user);
        setIsAuthenticated(true);
        setFirebaseInitializing(false);
        await refreshDaxSolverUser(user);
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setFirebaseInitializing(false);
        setDaxSolverUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // On browser reload, the firebase auth state is not immediately available before requests that optionally require auth tokens.
  if (firebaseInitializing) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, daxSolverUser, refreshDaxSolverUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
