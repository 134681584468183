import React from 'react';

export type DataTable = {
  title: string;
  columns: { name: string; dataType: string }[];
};

interface IDataTablesViewProps {
  tables: DataTable[];
}
export const DataTablesView: React.FC<IDataTablesViewProps> = ({ tables }) => {
  return (
    <div className="flex flex-col space-y-4 p-4">
      {tables.map((table, index) => (
        <div
          key={index}
          className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box"
        >
          <input type="checkbox" />
          <div className="collapse-title text-lg font-medium text-primary">
            Table {index + 1}: {table.title}
          </div>
          <div className="collapse-content text-secondary">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Data Type</th>
                </tr>
              </thead>
              <tbody>
                {table.columns.map((column, colIndex) => (
                  <tr key={colIndex}>
                    <td>{column.name}</td>
                    <td>{column.dataType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};
