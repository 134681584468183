import React from 'react';
import { DataTable, DataTablesView } from '.';

interface IDataTablesContainerViewProps {
  tables: DataTable[];
}
export const DataTablesViewContainer: React.FC<
  IDataTablesContainerViewProps
> = ({ tables }) => {
  return <DataTablesView tables={tables} />;
};
