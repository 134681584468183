import { useCallback, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useAuth } from '../../auth/authContext';
import { createCheckoutSession } from '../../services/accountsService';
import AuthComponentContainer from '../Auth/container';
import { SubscriptionTier } from '../../models/Plan';

export interface EmbeddedCheckoutButtonProps {
  priceId?: string;
}

export const EmbeddedCheckoutButton: React.FC<EmbeddedCheckoutButtonProps> = ({
  priceId,
}) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  );
  const [showCheckout, setShowCheckout] = useState(false);
  const modalRef = useRef<HTMLDialogElement>(null);
  const { user, daxSolverUser } = useAuth();

  const fetchClientSecret = useCallback(async () => {
    if (!user) {
      console.error('Must be signed in to checkout!');
      return;
    }
    if (!priceId) {
      console.error('No priceId provided');
      return;
    }
    const authToken = await user.getIdToken();
    const checkoutSessionResponse = await createCheckoutSession(
      authToken,
      priceId,
    );
    if (!checkoutSessionResponse) {
      console.error('Failed to create checkout session');
      return;
    }
    return checkoutSessionResponse.client_secret;
  }, [user, priceId]);

  const options = { fetchClientSecret };

  const handleCheckoutClick = () => {
    setShowCheckout(true);
    modalRef.current?.showModal();
  };

  const handleCheckoutClose = () => {
    setShowCheckout(false);
    modalRef.current?.close();
  };

  if (!priceId) {
    return null;
  }

  let modalContent = null;
  if (!user) {
    modalContent = (
      <div className="mb-4">
        <p className="text-primary">Please sign in before checkout</p>
        <AuthComponentContainer />
      </div>
    );
  } else if (
    daxSolverUser?.subscription_plan == SubscriptionTier.free &&
    showCheckout
  ) {
    modalContent = (
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  } else if (
    daxSolverUser?.subscription_plan != SubscriptionTier.free &&
    showCheckout
  ) {
    modalContent = (
      <div className="mb-4">
        <p className="text-primary">
          You have an active subscription - {daxSolverUser?.subscription_plan}.
          Cancel on your stripe customer portal before subscribing to a new
          plan.
        </p>
      </div>
    );
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={handleCheckoutClick}>
        Subscribe
      </button>
      <dialog ref={modalRef} className="modal">
        <div className="modal-box w-11/12 max-w-7xl">
          <h3 className="font-bold text-2xl pb-2 text-primary">
            {' '}
            Checkout | Powered by Stripe{' '}
          </h3>
          <div>
            {/* Prompt users to signin before checkout if they aren't already signed in. */}
            {/* {user ? (
                            daxSolverUser?.subscription_plan == SubscriptionTier.free && showCheckout ? (
                                <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                                    <EmbeddedCheckout />
                                </EmbeddedCheckoutProvider>
                            ): (
                                <div className="mb-4">
                                    <p className="text-primary">You have an active subscription - {daxSolverUser?.subscription_plan}. Cancel on your stripe customer portal before subscribing to a new plan.</p>
                                </div>
                            )
                        ) : (
                            <div className="mb-4">
                                <p className="text-primary">Please sign in before checkout</p>
                                <AuthComponentContainer />
                            </div>
                        )} */}
            {modalContent}
          </div>
          <div className="modal-action">
            <button className="btn btn-primary" onClick={handleCheckoutClose}>
              Close
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};
