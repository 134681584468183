import React, { useState } from 'react';
import { ISemanticModelMetadata, ISemanticModelUploadPayload } from '../../models/SemanticModel';

interface IModelManagementProps {
  uploadedModels: Pick<ISemanticModelMetadata, 'modelId' | 'modelName' | 'publicModel' | 'modelType'>[];
  dragActive: boolean;
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>;
  newModelDetails: ISemanticModelUploadPayload;
  setNewModelDetails: React.Dispatch<React.SetStateAction<ISemanticModelUploadPayload>>;
  handleDragEnter: (e: any) => void;
  handleDragLeave: (e: any) => void;
  handleDrop: (e: any) => void;
  handleFileChange: (e: any) => void;
  handleSubmit: () => void;
  handleModelDelete: (modelId: string) => Promise<void>;
}
export const ModelManagement: React.FC<IModelManagementProps> = ({
  uploadedModels,
  dragActive,
  setDragActive,
  newModelDetails,
  setNewModelDetails,
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  handleSubmit,
  handleFileChange,
  handleModelDelete,
}) => {
  return (
    <div className="container mx-auto px-4 py-6 space-y-6">
      {/* Upload Section */}
      <div
        className={`border-2 ${dragActive ? 'border-blue-500' : 'border-gray-300'} border-dashed rounded-lg p-10 text-center`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* Upload Icon */}
        <div className="flex flex-col items-center justify-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-16 h-16 text-blue-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
          <h2 className="text-lg font-bold text-gray-600">
            Upload a pbix file
          </h2>
          <p className="text-sm text-gray-500">
            Drag & drop a file here or click to upload
          </p>

          {/* File Input */}
          <input
            type="file"
            accept=".pbix"
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Choose File
          </label>

          {/* Uploaded File Name */}
          {newModelDetails.fileName && (
            <p className="text-sm text-gray-500 mt-2">File: {newModelDetails.fileName}</p>
          )}

          {/* Model Name Input */}
          <div className="mt-6 w-full">
            <input
              type="text"
              placeholder="Enter model name"
              value={newModelDetails.modelName}
              onChange={(e) => setNewModelDetails({...newModelDetails, modelName: e.target.value})}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          {/* Model description */}
          <div className="mt-6 w-full">
            <textarea
              placeholder="Enter model description"
              value={newModelDetails.modelDescription}
              onChange={(e) => setNewModelDetails({...newModelDetails, modelDescription: e.target.value})}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
            >
            </textarea>
          </div>

          {/* Model Privacy Checkbox */}
          <div className="mt-6 w-full">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="public"
                  value="Public"
                  checked={newModelDetails.public == true}
                  onChange={(e) => setNewModelDetails({...newModelDetails, public: true})}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2 text-gray-300">Public</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="public"
                  value="Private"
                  checked={newModelDetails.public == false}
                  onChange={(e) => setNewModelDetails({...newModelDetails, public: false})}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2 text-gray-300">Private</span>
              </label>
            </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded-md"
            disabled={!newModelDetails.file || !newModelDetails.modelName}
          >
            Upload Model
          </button>
        </div>
      </div>

      {/* Uploaded Models Section */}
      <div className="space-y-2">
        <h3 className="text-xl font-bold text-gray-600">
          Previously Uploaded Models
        </h3>
        {uploadedModels.map((model) => (
          <div
            key={model.modelId}
            className="flex justify-between items-center p-4 border border-gray-300 rounded-md"
          >
            <span className="text-lg font-semibold text-gray-600">
              {`${model.modelName} | ${model.publicModel ? `Public`: `Private`} | ${model.modelType}`}
            </span>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={() => handleModelDelete(model.modelId)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
