import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Solver from './pages/Solver';
import App from './App';
import DashboardBase from './pages/DashboardBase';
import Dashboard from './pages/Dashboard';
import ErrorElementContainer from './components/Error/container';
import { Jobs } from './pages/Jobs';
import { Pricing } from './pages/Pricing';
import { PostPayment } from './pages/PostPayment';
import { ManageModels } from './pages/ManageModels';
import { CodePlayground } from './pages/CodePlayground';
import { CodePlaygroundList } from './pages/CodePlaygroundList';
import { CourseList } from './pages/CourseList';

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <DashboardBase />,
        children: [
          { path: '/', element: <Dashboard /> },
          { path: 'questions/:questionId', element: <Solver /> },
          { path: 'jobs', element: <Jobs /> },
          { path: 'pricing', element: <Pricing /> },
          { path: 'postpay', element: <PostPayment /> },
          { path: 'manage-models', element: <ManageModels /> },
          { path: 'playground/:playgroundId', element: <CodePlayground /> },
          { path: 'playgrounds', element: <CodePlaygroundList /> },
          { path: 'courses', element: <CourseList /> },
        ],
      },
    ],
    errorElement: <ErrorElementContainer />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
