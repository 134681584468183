import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCRtRmMYxwoW4wgX1f1-Vx78E7nlLJUpkM',
  authDomain: 'dax-solver.firebaseapp.com',
  projectId: 'dax-solver',
  storageBucket: 'dax-solver.appspot.com',
  messagingSenderId: '866008899181',
  appId: '1:866008899181:web:49e0157021b757f3c8a964',
  measurementId: 'G-JL718JHF69',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
