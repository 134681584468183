import { useEffect, useState } from 'react';


export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || 'https://api.daxsolver.com';

// Use in an upcoming commit
export function useDataFetch<T>(fetchMethod: () => Promise<T>) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchMethod();
      setData(response);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchMethod]);

  const refetch = () => {
    fetchData();
  };

  return { data, error, isLoading, refetch };
}
