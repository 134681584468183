import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';


const SearchBar: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = React.useState('');

  // Keep search term in sync with the URL
  useEffect(() => {
    // On initial load, search param supersedes the input box value
    const name = searchParams.get('name') || '';
    if (name !== searchTerm) {
      setSearchTerm(name);
    }
  }, [searchParams]);

  // Context provider listens to changes in search params and will auto update results.
  const search = () => {
    if (searchTerm.trim()) {
      searchParams.set('name', searchTerm);
    } else {
      searchParams.delete('name');
    }
    
    setSearchParams(searchParams);
  }

  return (
    <div className="flex items-center space-x-2 mb-8">
      <input
        type="text"
        placeholder="Find a problem"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.trim())}
        className="input input-bordered w-full max-w-xs"
      />
      <button className="btn btn-primary" onClick={search}>Search</button>
    </div>
  );
};

export default SearchBar;
