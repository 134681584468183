import { IPlaygroundExecutionResult } from '../models/ExecutionResult';
import { IPlaygroundRequestPayload, PlaygroundDetails } from '../models/Playground';
import { BASE_API_URL } from './utils';
// TODO

export const getAllUserCodePlaygrounds = async (
  authToken?: string | null,
): Promise<PlaygroundDetails[]> => {
  try {
    const headers = new Headers();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
    const response = await fetch(`${BASE_API_URL}/api/v1/playground/playground-manager`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch playgrounds');
      return [];
    }
    const respData = await response.json();
    const playgrounds: PlaygroundDetails[] = respData;
    return playgrounds;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const deleteCodePlayground = async (
  authToken: string,
  playGroundId: number,
): Promise<void> => {
  try {
    const headers = new Headers({
      Authorization: authToken,
    });
    const response = await fetch(`${BASE_API_URL}/api/v1/playground/playground-manager/${playGroundId}`, {
      method: 'DELETE',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to delete playground');
      return;
    }
    console.log('Playground deleted successfully');
  } catch (e) {
    console.error(e);
  }
};

export const createNewPlayground = async (
  authToken: string,
  playgroundDetails: IPlaygroundRequestPayload,
): Promise<PlaygroundDetails> => {
  try {
    const headers = new Headers({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });
    const response = await fetch(`${BASE_API_URL}/api/v1/playground/playground-manager`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(playgroundDetails),
    });
    if (!response.ok) {
      console.error('Failed to create playground');
      return {} as PlaygroundDetails;
    }
    const playground: PlaygroundDetails = await response.json();
    return playground;
  } catch (e) {
    console.error(e);
    return {} as PlaygroundDetails;
  }
};

export const updateExistingPlayground = async (
  authToken: string,
  playgroundId: string,
  playgroundDetails: IPlaygroundRequestPayload,
): Promise<void> => {};

export const runPlaygroundCode = async (
  authToken: string,
  playgroundId: number,
  code: string,
): Promise<IPlaygroundExecutionResult> => {
  try {
    const headers = new Headers({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });
    const payload = {
      code: code,
      playground_id: playgroundId,
    }
    const response = await fetch(`${BASE_API_URL}/api/v1/playground/run`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      console.error('Failed to run playground code');
      return {} as IPlaygroundExecutionResult;
    }
    const result: IPlaygroundExecutionResult = await response.json();
    return result;
  } catch (e) {
    console.error(e);
    return {} as IPlaygroundExecutionResult;
  }
}