import { useState, useEffect } from 'react';
import { useAuth } from '../auth/authContext';
import { getAllUserCodePlaygrounds } from '../services/playgroundService';
import { PlaygroundDetails } from '../models/Playground';

export const useGetUserPlaygrounds = () => {
  const [userCodePlaygrounds, setUserCodePlaygrounds] = useState<
    PlaygroundDetails[]
  >([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const { user } = useAuth();

  useEffect(() => {
    const loadData = async () => {
      // Most API ops do not explicitly require an auth token
      // If no token and user exceeded free limit they get an error and we can
      // show a popup and prompt signin and subscription
      const authToken = user ? await user.getIdToken() : null;
      const userCodePlaygroundResult =
        await getAllUserCodePlaygrounds(authToken);
      setUserCodePlaygrounds(userCodePlaygroundResult);
    };

    loadData();
  }, [user, refreshTrigger]);

  return { userCodePlaygrounds, refreshPlaygrounds: () => setRefreshTrigger((prev) => prev + 1) };
};
