import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllCourses } from '../hooks/useGetCourses';

export const CourseList = () => {
  const courses = useGetAllCourses();
  const navigate = useNavigate();

  return (
    <div className="container mx-auto p-4">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-center mb-6">Course List</h1>

      {/* Courses Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="card w-72 bg-base-100 shadow-xl w-full"
          >
            <div className="card-body">
              <h2 className="card-title text-primary">{course.title}</h2>
              <p>{course.description}</p>
              <div className="card-actions justify-end">
                <a
                  className="btn btn-primary"
                  onClick={() => navigate('/questions/1')}
                >
                  Start Course
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
