import React from 'react';

export const Jobs: React.FC = () => {
  return (
    <div className="min-h-screen p-6 bg-base-200">
      {/* Title */}
      <h1 className="text-4xl font-bold text-center mb-8">DAX Jobs</h1>

      {/* Sponsored Job Section */}
      <div className="max-w-4xl mx-auto mb-8">
        <div className="card bg-primary text-primary-content">
          <div className="card-body">
            <h2 className="card-title text-2xl">
              Sponsored Job: Senior DAX Developer
            </h2>
            <p className="text-lg">
              We are looking for an experienced Senior DAX Developer to join our
              fast-growing team.
            </p>
            <p className="text-sm mt-2">
              Location: Remote | Salary: $120k - $150k/year | Type: Full-time
            </p>
            <div className="card-actions justify-end">
              <button className="btn btn-secondary">Apply Now</button>
            </div>
          </div>
        </div>
      </div>

      {/* Job Listings Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 max-w-6xl mx-auto">
        {/* Sample job card */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">DAX Engineer at XYZ Corp</h2>
            <p>Join our analytics team to build dynamic DAX solutions.</p>
            <p className="text-sm mt-2">
              Location: New York, NY | Type: Full-time
            </p>
            <div className="card-actions justify-end">
              <button className="btn btn-primary">View Details</button>
            </div>
          </div>
        </div>

        {/* Another job card */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Junior DAX Analyst at DataCorp</h2>
            <p>
              Work with seasoned professionals on DAX-based reporting solutions.
            </p>
            <p className="text-sm mt-2">
              Location: London, UK | Type: Part-time
            </p>
            <div className="card-actions justify-end">
              <button className="btn btn-primary">View Details</button>
            </div>
          </div>
        </div>

        {/* More job listings */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">DAX Consultant at FinTech</h2>
            <p>Provide expert DAX support for various financial projects.</p>
            <p className="text-sm mt-2">Location: Remote | Type: Contract</p>
            <div className="card-actions justify-end">
              <button className="btn btn-primary">View Details</button>
            </div>
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">DAX Specialist at Data Solutions</h2>
            <p>Lead DAX development projects across multiple domains.</p>
            <p className="text-sm mt-2">
              Location: San Francisco, CA | Type: Full-time
            </p>
            <div className="card-actions justify-end">
              <button className="btn btn-primary">View Details</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
