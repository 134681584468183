import { IDaxSolverUser } from '../models/DaxSolverUser';
import { IPlanList } from '../models/Plan';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || 'https://api.daxsolver.com';

export const createCheckoutSession = async (
  authToken: string,
  priceId: string,
) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: authToken,
    });

    const payload = {
      priceId: priceId,
    };
    const response = await fetch(
      `${BASE_API_URL}/api/v1/accounts/checkout-session`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      console.error('Failed to create checkout session');
      return null;
    }
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getPlans = async () => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    const response = await fetch(`${BASE_API_URL}/api/v1/accounts/plans`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch plans');
      return null;
    }
    const data: IPlanList = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

enum Status {
  PENDING = 'pending',
  COMPLETED = 'completed',
}
export interface ICheckoutSessionStatus {
  status: Status;
}
export const checkSessionStatus = async (
  authToken: string,
  sessionId: string,
) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: authToken,
    });

    const response = await fetch(
      `${BASE_API_URL}/api/v1/accounts/checkout-session?session_id=${sessionId}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch checkout session status');
      return null;
    }
    const respData = await response.json();
    const data: ICheckoutSessionStatus = respData;
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getDaxSolverUser = async (authToken: string) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: authToken,
    });

    const response = await fetch(`${BASE_API_URL}/api/v1/accounts/profile`, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      console.error('Failed to fetch user profile');
      return null;
    }
    const data: IDaxSolverUser = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
