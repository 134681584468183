import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { IQuestion } from '../models/Question';
import { IExecResult } from '../models/ExecutionResult';
import { fetchQuestion, evaluateSolution } from '../services/questionService';
import { PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Panel } from 'react-resizable-panels';
import { SolverCodeEditorPanelContainer } from '../components/SolverCodeEditorPanel/container';
import { QuestionDescriptionPanelContainer } from '../components/ProblemDescriptionPanel/container';
import { ResultsPanelContainer } from '../components/ResultsPanel/container';
import { editor } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { SolverContext } from '../contexts/Solver';
import { PanelSeparatorSvg } from '../assets/svgs/PanelSeparator';

const Solver: React.FC = () => {
  const [question, setQuestion] = useState<IQuestion | null>(null);
  const [codeInput, setCodeInput] = useState('');
  const [results, setResults] = useState<IExecResult | null>(null);
  const { questionId } = useParams<{ questionId: string }>();
  const { user } = useAuth();
  const monacoRef = useRef<Monaco | null>(null);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

  useEffect(() => {
    const loadData = async () => {
      if (questionId) {
        const authToken = user ? await user.getIdToken() : null;
        const questionItem = await fetchQuestion(questionId, authToken);
        setQuestion(questionItem);
      } else {
        console.error('No question ID provided');
      }
    };

    loadData();
  }, [user]);

  const handleRun = async (submit: boolean = false) => {
    if (!question) {
      console.error('No question loaded');
      return;
    }
    const authToken = user ? await user.getIdToken() : null;
    const evaluationResult = await evaluateSolution(
      String(question.id),
      codeInput,
      submit,
      authToken,
    );
    setResults(evaluationResult);
  };

  if (!question) {
    return <div> No question matching that Id found</div>;
  }

  return (
    <SolverContext.Provider
      value={{
        question,
        codeInput,
        setCodeInput,
        results,
        editorRef,
        monacoRef,
      }}
    >
      <PanelGroup
        direction="horizontal"
        className="w-[calc(100vw-152px)]"
        style={{
          display: 'flex',
          height: '100vh',
          padding: '1rem',
          width: 'calc(100vw-120px)',
        }}
      >
        <Panel defaultSize={50} minSize={25}>
          <QuestionDescriptionPanelContainer question={question} />
        </Panel>
        <PanelResizeHandle
          className="flex justify-center items-center"
          style={{ width: '20px' }}
        >
          <PanelSeparatorSvg direction={'vertical'} />
        </PanelResizeHandle>
        <Panel
          defaultSize={50}
          minSize={45}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <PanelGroup
            direction="vertical"
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Panel defaultSize={60} minSize={50}>
              <SolverCodeEditorPanelContainer handleRun={handleRun} />
            </Panel>
            <PanelResizeHandle
              className="flex justify-center items-center"
              style={{ height: '20px' }}
            >
              <PanelSeparatorSvg direction={'horizontal'} />
            </PanelResizeHandle>
            <Panel defaultSize={40} minSize={20}>
              <ResultsPanelContainer results={results} />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </SolverContext.Provider>
  );
};

export default Solver;
