import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IQuestion, IQuestionFilters } from '../models/Question';
import { fetchTopics } from '../services/questionService';
import { fetchQuestions } from '../services/questionService';
import { useAuth } from '../auth/authContext';
import SearchBar from '../components/SearchBar';
import { DashboardContext } from '../contexts/Dashboard';
import { QuestionListContainer } from '../components/QuestionList/container';
import Header from '../components/PageHeader';
import { DashboardTopCoursesContainer } from '../components/DashboardTopCourses/container';
import { ITopic } from '../models/Topic';

const Dashboard: React.FC = () => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const { user } = useAuth();

  // When filter params are changed in `/` route, the dashboard, auto reload questions with new filters.
  const location = useLocation();
  useEffect(() => {
    const loadFilteredQuestions = async () => {
      const queryParams = new URLSearchParams(location.search);
      const topicFilter = queryParams.getAll('topic');
      const difficultyFilter = queryParams.getAll('difficulty');
      const nameFilter = queryParams.getAll('name');
      const filters: IQuestionFilters = {
        topic: topicFilter,
        difficulty: difficultyFilter,
        name: nameFilter,
      }
      console.log('Filters:', filters);
      
      // this conditional check may no longer be needed since I have enforced waiting till firebase is initialized
      const authToken = user ? await user.getIdToken() : null;
      const paginatedQuestions = await fetchQuestions(authToken, filters);
      const availableTopics = await fetchTopics(authToken);
      setQuestions(paginatedQuestions as IQuestion[]);
      setTopics(availableTopics as ITopic[]);
    };

    loadFilteredQuestions();
  }, [user, location]);

  return (
    <DashboardContext.Provider value={{ questions: questions, topics: topics }}>
      <Header />
      <div className="min-h-screen bg-base-200 p-8">
        <DashboardTopCoursesContainer />

        <SearchBar />
        <QuestionListContainer />
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
