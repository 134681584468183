import { useState, useEffect } from 'react';
import { getPlans } from '../services/accountsService';
import { IPlanList } from '../models/Plan';

export const useGetPlans = () => {
  const [planList, setPlanList] = useState<IPlanList | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const planList = await getPlans();
      setPlanList(planList);
    };

    loadData();
  }, []);

  return planList;
};
