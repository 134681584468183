import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { IQuestion } from '../../models/Question';
import { useDashboardContext } from '../../contexts/Dashboard';
import { ITopic } from '../../models/Topic';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

interface ISectionProps {
  title: string;
  items: IQuestion[];
  topics: ITopic[];
}

const getBarColor = (difficulty: string, index: number) => {
  if (difficulty === 'easy' && index === 0) return 'bg-green-500';
  if (difficulty === 'medium' && index < 3) return 'bg-yellow-500';
  if (difficulty === 'hard' && index < 5) return 'bg-red-500';
  return 'bg-gray-300';
};

const getAttemptStateColor = (attempt_status?: string) => {
  if (attempt_status === 'success') return 'fill-green-500';
  if (attempt_status === 'failed') return 'fill-yellow-500';
  return 'fill-gray-300';
};

// Each course's questions can be loaded separately and displayed with this component
const QuestionList: React.FC<ISectionProps> = ({
  title,
  items,
  topics,
}) => {
  const [showAllTopics, setShowAllTopics] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const displayedTopics = showAllTopics ? topics : topics.slice(0, 5);
  const [selectedTopics, setSelectedTopics] = React.useState<string[]>(searchParams.getAll('topic'));

  const toggleShowAllTopics = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowAllTopics(!showAllTopics);
  };

  const isDifficultyChecked = (difficulty: string) => {
    return searchParams.getAll('difficulty').includes(difficulty);
  }

  // Filters update the search params, while the context listens on changes to the search params in path `/`
  const onDifficultyFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const difficulty = e.target.value;
    
    if (e.target.checked) {
      if (!searchParams.getAll('difficulty').includes(difficulty)) {
        searchParams.append('difficulty', difficulty);
      }
    } else {
      // Remove that specific difficulty from the search params
      const currentDifficulties = searchParams.getAll('difficulty');
      searchParams.delete('difficulty');
      currentDifficulties.forEach((diff) => {
        if (diff !== difficulty) {
          searchParams.append('difficulty', diff);
        }
      });      
    };

    setSearchParams(searchParams);
  };

  const handleTopicClick = (topicName: string) => {
    if (selectedTopics.includes(topicName)) {
      const updatedTopics = selectedTopics.filter((t) => t !== topicName);
      setSelectedTopics(updatedTopics);
      searchParams.delete('topic');
      updatedTopics.forEach((t) => searchParams.append('topic', t));
    } else {
      setSelectedTopics([...selectedTopics, topicName]);
      searchParams.append('topic', topicName);
    }
    setSearchParams(searchParams);
  };

  return (
    <div className="flex space-x-8">
      {/* Question List */}
      <div className="flex-grow">
        <h2 className="text-xl mb-4">Question List</h2>
        <div className="overflow-x-auto">
          <table className="table w-full">
            {/* Table Head */}
            <thead>
              <tr>
                <th>Title</th>
                <th>Difficulty</th>
                <th>Attempted</th>
                {/* Disabling likes until we have a mechanism to track them. */}
                {/* <th>Likes</th> */}
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <a
                      href={`/questions/${item.id}`}
                      className="text-primary hover:underline"
                    >
                      {item.title}
                    </a>
                  </td>
                  <td>
                    <div className="flex space-x-1" title={item.difficulty}>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <div
                          key={i}
                          className={`h-4 w-4 rounded-full ${getBarColor(item.difficulty, i)}`}
                        ></div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <span><IoIosCheckmarkCircleOutline className={`h-6 w-6 ${getAttemptStateColor(item.attempt_status)}`} /></span>
                  </td>
                  {/* <td>
                    <span className="text-red-500">❤️ {125}</span>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Filters Section */}
      <div className="w-72 space-y-6">
        {/* Difficulty Filter Section */}
        <div className="card bg-base-100 shadow-lg p-4">
          <h2 className="card-title mb-4">Filter by Difficulty</h2>
          <div className="form-control space-y-2">
            <label className="label cursor-pointer">
              <span className="label-text">Easy</span>
              <input type="checkbox" value="easy" checked={isDifficultyChecked("easy")} className="checkbox checkbox-primary" onChange={(e) => onDifficultyFilter(e)}/>
            </label>
            <label className="label cursor-pointer">
              <span className="label-text">Medium</span>
              <input type="checkbox" value="medium" checked={isDifficultyChecked("medium")} className="checkbox checkbox-primary" onChange={(e) => onDifficultyFilter(e)}/>
            </label>
            <label className="label cursor-pointer">
              <span className="label-text">Hard</span>
              <input type="checkbox" value="hard" checked={isDifficultyChecked("hard")} className="checkbox checkbox-primary" onChange={(e) => onDifficultyFilter(e)}/>
            </label>
          </div>
        </div>

        {/* Topic Functions Filter Section */}
        <div className="card bg-base-100 shadow-lg p-4">
          <h2 className="card-title mb-4">Topic Functions</h2>
          <div className="flex flex-wrap gap-2 text-primary-400">
            {/* Example Badges with Method Names and Counts */}
            {displayedTopics.map((topic) => {
              const isSelected = selectedTopics.includes(topic.name);
              return (
                <div key={topic.id} className={`badge cursor-pointer p-4 ${isSelected ? "badge-primary": "badge-outline"}`} onClick={() => handleTopicClick(topic.name)}>
                  ${topic.name}{' '}
                  <span className="ml-1 badge badge-secondary">{topic.problem_count}</span>
                </div>
              );
            })}
          </div>
          {/* See All Button. Initial call loads all topics, the see all expands them to be viewable. */}
          <a
            href="#"
            onClick={toggleShowAllTopics}
            className="text-sm text-blue-500 mt-4 inline-block hover:underline"
          >
            {showAllTopics ? 'Show Less' : 'Show All'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuestionList;
