import react, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ReactFlow } from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import { DataTablesViewContainer } from '../DataTablesView/container';
import { CodePlaygroundContext } from '../../contexts/CodePlayground';

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

const markdown = `Markdown supports two style of links: *inline* and *reference*.

In both styles, the link text is delimited by [square brackets].

To create an inline link, use a set of regular parentheses immediately
after the link text's closing square bracket. Inside the parentheses,
put the URL where you want the link to point, along with an *optional*
title for the link, surrounded in quotes. For example:

    This is [an example](http://example.com/ "Title") inline link.

    [This link](http://example.net/) has no title attribute.

Will produce:

    <p>This is <a href="http://example.com/" title="Title">
    an example</a> inline link.</p>

    <p><a href="http://example.net/">This link</a> has no
    title attribute.</p>

If you're referring to a local resource on the same server, you can
use relative paths:

    See my [About](/about/) page for details.

Reference-style links use a second set of square brackets, inside
which you place a label of your choosing to identify the link:

    This is [an example][id] reference-style link.

You can optionally use a space to separate the sets of brackets:

    This is [an example] [id] reference-style link.

Then, anywhere in the document, you define your link label like this,
on a line by itself:

    [id]: http://example.com/  "Optional Title Here"

That is:

*   Square brackets containing the link identifier (optionally
    indented from the left margin using up to three spaces);
*   followed by a colon;
*   followed by one or more spaces (or tabs);
*   followed by the URL for the link;
*   optionally followed by a title attribute for the link, enclosed
    in double or single quotes, or enclosed in parentheses.

The following three link definitions are equivalent:

    [foo]: http://example.com/  "Optional Title Here"
    [foo]: http://example.com/  'Optional Title Here'
    [foo]: http://example.com/  (Optional Title Here)
`;

const mockTables = [
  {
    title: 'Users',
    columns: [
      { name: 'ID', dataType: 'Integer' },
      { name: 'Username', dataType: 'Varchar(255)' },
      { name: 'Email', dataType: 'Varchar(255)' },
    ],
  },
  {
    title: 'Products',
    columns: [
      { name: 'Product ID', dataType: 'Integer' },
      { name: 'Product Name', dataType: 'Varchar(255)' },
      { name: 'Price', dataType: 'Decimal' },
    ],
  },
  {
    title: 'Orders',
    columns: [
      { name: 'Order ID', dataType: 'Integer' },
      { name: 'Order Date', dataType: 'Date' },
      { name: 'Customer ID', dataType: 'Integer' },
    ],
  },
  {
    title: 'Payments',
    columns: [
      { name: 'Payment ID', dataType: 'Integer' },
      { name: 'Amount', dataType: 'Decimal' },
      { name: 'Payment Date', dataType: 'Date' },
    ],
  },
];

// Will support Markdown for better formatting.
export const PlaygroundMetadataPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    | 'semantic-model-description'
    | 'data-model-tables'
    | 'data-model-relationships'
  >('data-model-tables');
  const {
    currentPlayground,
  } = useContext(CodePlaygroundContext);

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center space-x-4 justify-between w-full">
        {/* Title */}
        <h2 className="text-2xl font-bold dark:text-white pb-4 mx-auto">
          {currentPlayground.name}
        </h2>

        {/* Dropdown for switching models */}
        {/* We are not yet supporting switching models */}
        <select
          className="select-info text-white p-2 rounded"
          defaultValue={currentPlayground.tabular_model.modelId}
          disabled
        >
          <option value={currentPlayground.tabular_model.modelId} disabled>
            {currentPlayground.tabular_model.modelName}
          </option>
          {/* <option value="" disabled>
            Switch Semantic models
          </option>
          {dataModelSwitchOptions.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))} */}
        </select>
      </div>

      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        {currentPlayground.description && (
          <button
            className={`tab ${activeTab === 'semantic-model-description' ? 'tab-active' : ''}`}
            role="tab"
            aria-label="Model Description"
            onClick={() => setActiveTab('semantic-model-description')}
          >
            Model Description
          </button>
        )}
        <button
          className={`tab ${activeTab === 'data-model-tables' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Data Model Tables"
          onClick={() => setActiveTab('data-model-tables')}
        >
          Data Tables
        </button>
        <button
          className={`tab ${activeTab === 'data-model-relationships' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Table Relationships"
          onClick={() => setActiveTab('data-model-relationships')}
        >
          Data Model Relationships
        </button>
      </div>

      {/* Tab Panels */}
      <div className="flex-1 relative overflow-hidden">
        {/* Question Description Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'semantic-model-description' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto p-4">
            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
          </div>
        </div>

        {/* Question Data Model Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-tables' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto">
            <DataTablesViewContainer tables={mockTables} />
          </div>
        </div>

        {/* Question Data Model Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-relationships' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full p-4">
            <ReactFlow
              nodes={initialNodes}
              edges={initialEdges}
              colorMode="dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
