import React, { useRef } from 'react';
import { PlaygroundCodeEditorPanel } from '.';
import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import {
  registerAutoComplete,
  registerDaxFormatterV2,
  registerDaxHover,
  registerDaxLanguage,
  registerDaxSnippets,
  registerErrorChecker,
} from './utils';
import daxData from './dax-data.json';
import { useCodeEditorContext } from '../../contexts/CodePlayground';

type PlaygroundCodeEditorPanelContainerProps = {
  handleRun: () => void;
};

export const PlaygroundCodeEditorPanelContainer: React.FC<
  PlaygroundCodeEditorPanelContainerProps
> = ({ handleRun }) => {
  const { editorRef, monacoRef, codeInput, setCodeInput } =
    useCodeEditorContext();
  const handleEditorDidMount = (
    editor: editor.IStandaloneCodeEditor,
    monaco: Monaco,
  ) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
    registerDaxLanguage(monaco, daxData);
    registerAutoComplete(monaco, daxData);
    registerErrorChecker(monaco);
    // registerDaxFormatter(monaco);
    registerDaxFormatterV2(monaco);
    registerDaxSnippets(monaco);
    registerDaxHover(monaco, daxData);
  };
  const onFormatCodeClick = () => {
    codeInput && editorRef.current
      ? editorRef.current.trigger(
          'editor',
          'editor.action.formatDocument',
          codeInput,
        )
      : console.log('');
  };
  return (
    <PlaygroundCodeEditorPanel
      input={codeInput}
      handleRun={handleRun}
      setInput={setCodeInput}
      handleEditorDidMount={handleEditorDidMount}
      onFormatCodeClick={onFormatCodeClick}
    />
  );
};
