import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { checkSessionStatus } from '../services/accountsService';

export const PostPayment: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sessionStatus, setSessionStatus] = useState<string | null>(null);
  const { user, refreshDaxSolverUser } = useAuth();

  useLayoutEffect(() => {
    // Only poll max 10 times before assuming terminal failure.
    let attempt = 0;

    const loadStatus = async () => {
      const session_id = searchParams.get('session_id');
      if (!session_id || !user) {
        console.error('Invalid session ID or user');
        return;
      }
      const authToken = await user.getIdToken();
      const statusResponse = await checkSessionStatus(authToken, session_id);
      if (!statusResponse) {
        console.error('Failed to fetch session status');
        return;
      }
      setSessionStatus(statusResponse.status);

      attempt++;
      if (attempt >= 10) {
        clearInterval(interval);
      }
    };

    const interval = setInterval(() => {
      loadStatus();
    }, 3000);

    if (sessionStatus === 'completed') {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [user, searchParams]);

  useEffect(() => {
    const handleSessionCompleted = async () => {
      if (sessionStatus === 'completed' && user) {
        await refreshDaxSolverUser(user);
        navigate('/');
      }
    };

    handleSessionCompleted();
  }, [sessionStatus, user]);

  return (
    <div className="flex min-h-screen text-white bg-base-200">
      <div className="align-items-center justify-center mt-4">
        <p>
          Updating your membership! You will be redirected to the dashboard once
          complete.
        </p>
      </div>
    </div>
  );
};
