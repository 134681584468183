export const toggleFullScreen = () => {
  const elem = document.documentElement as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
  const docForExitFullScreen = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
  };

  // If not in fullscreen mode, request fullscreen
  if (!document.fullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  } else {
    // If in fullscreen mode, exit fullscreen
    if (docForExitFullScreen.exitFullscreen) {
      docForExitFullScreen.exitFullscreen();
    } else if (docForExitFullScreen.webkitExitFullscreen) {
      /* Safari */
      docForExitFullScreen.webkitExitFullscreen();
    } else if (docForExitFullScreen.msExitFullscreen) {
      /* IE11 */
      docForExitFullScreen.msExitFullscreen();
    }
  }
};
