import React, { useState } from 'react';
import { FaArrowLeft, FaBars, FaLock, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export const AutoQuestionAdjusterNav = () => {
  const questions = [
    {
      title: 'How many measures are in a plate?',
      url: '/questions/measures-testing',
      hasSuccessfulAttempt: true,
    },
    {
      title: 'How does Reddit use Dax?',
      url: '/questions/reddit-dax',
      hasSuccessfulAttempt: false,
    },
    {
      title: 'What are the common dax measures?',
      url: '/questions/dax-measures',
      hasSuccessfulAttempt: true,
    },
    {
      title: 'How do I perform a square root?',
      url: '/questions/square root',
      hasSuccessfulAttempt: false,
    },
    {
      title: 'How to optimize dax queries?',
      url: '/questions/dax-optimize',
      hasSuccessfulAttempt: true,
    },
  ];

  const navigate = useNavigate();

  const handleQuestionChange = (event: any) => {
    const selectedUrl = event.target.value;
    if (selectedUrl) {
      navigate(selectedUrl); // Navigate to the selected question's URL
    }
  };

  const handleBackToCourseClick = (event: any) => {
    navigate('/courses');
  };

  return (
    <div className="flex items-center justify-between bg-base-200 text-base-content p-2 rounded-lg shadow-md w-full">
      {/* Back to Questions Button */}
      <button
        className="flex items-center space-x-2 text-sm"
        onClick={handleBackToCourseClick}
      >
        <FaArrowLeft />
        <span>Back to Courses</span>
      </button>

      <button className="flex items-center space-x-2">
        <FaArrowLeft />
      </button>

      {/* Middle Section with Select Dropdown */}
      <div className="flex items-center space-x-2 bg-base-300 px-4 py-2 rounded-lg w-6/12">
        <FaLock />
        <select
          className="bg-transparent outline-none cursor-pointer w-full"
          onChange={handleQuestionChange}
          defaultValue=""
        >
          {questions.map((question, index) => (
            <option key={index} value={question.url}>
              {question.title}
              {question.hasSuccessfulAttempt && <span>✅</span>}
            </option>
          ))}
        </select>
      </div>

      {/* Right Arrow Button */}
      <button className="flex items-center">
        <FaArrowRight />
      </button>
    </div>
  );
};
