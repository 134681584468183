import React, { useState } from 'react';
import { IQuestion } from '../../models/Question';
import sanitizeHtml from 'sanitize-html';

import { ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { DataTablesViewContainer } from '../DataTablesView/container';
import { AutoQuestionAdjusterNav } from '../AutoQuestionAdjusterNav/container';

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];


const mockTables = [
  {
    title: 'Users',
    columns: [
      { name: 'ID', dataType: 'Integer' },
      { name: 'Username', dataType: 'Varchar(255)' },
      { name: 'Email', dataType: 'Varchar(255)' },
    ],
  },
  {
    title: 'Products',
    columns: [
      { name: 'Product ID', dataType: 'Integer' },
      { name: 'Product Name', dataType: 'Varchar(255)' },
      { name: 'Price', dataType: 'Decimal' },
    ],
  },
  {
    title: 'Orders',
    columns: [
      { name: 'Order ID', dataType: 'Integer' },
      { name: 'Order Date', dataType: 'Date' },
      { name: 'Customer ID', dataType: 'Integer' },
    ],
  },
  {
    title: 'Payments',
    columns: [
      { name: 'Payment ID', dataType: 'Integer' },
      { name: 'Amount', dataType: 'Decimal' },
      { name: 'Payment Date', dataType: 'Date' },
    ],
  },
];

// Will support Markdown for better formatting.
export const QuestionDescription: React.FC<{ question: IQuestion | null }> = ({
  question,
}) => {
  const [activeTab, setActiveTab] = useState<
    'question-description' | 'data-model-tables' | 'data-model-relationships'
  >('question-description');
  const sanitizedHtml = sanitizeHtml(question?.description ?? '')

  return (
    <div className="flex flex-col h-full">
      <AutoQuestionAdjusterNav />
      {/* Title */}
      <h2 className="text-2xl font-bold dark:text-white pb-4">
        {question?.title ?? 'Problem with Unknown Title'}
      </h2>

      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        <button
          className={`tab ${activeTab === 'question-description' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Question Description"
          onClick={() => setActiveTab('question-description')}
        >
          Question Description
        </button>
        <button
          className={`tab ${activeTab === 'data-model-tables' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Data Model Tables"
          onClick={() => setActiveTab('data-model-tables')}
        >
          Data Tables
        </button>
        <button
          className={`tab ${activeTab === 'data-model-relationships' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Table Relationships"
          onClick={() => setActiveTab('data-model-relationships')}
        >
          Data Model Relationships
        </button>
      </div>

      {/* Tab Panels */}
      <div className="flex-1 relative overflow-hidden">
        {/* Question Description Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'question-description' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto p-4">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          </div>
        </div>

        {/* Question Data Model Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-tables' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto">
            <DataTablesViewContainer tables={mockTables} />
          </div>
        </div>

        {/* Question Data Model Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-relationships' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full p-4">
            <ReactFlow
              nodes={initialNodes}
              edges={initialEdges}
              colorMode="dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
