export interface IPrice {
  id: string;
  currency: string;
  unit_amount: number;
  type: string;
}

export interface IPlanList {
  plans: {
    monthly: IPlanTypes;
    annual: IPlanTypes;
  };
}

export interface IPlanTypes {
  individual: IPrice;
  professional: IPrice;
}

export enum SubscriptionTier {
  free = 'free',
  individual = 'individual',
  professional = 'professional',
}
