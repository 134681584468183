import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { IPlaygroundExecutionResult } from '../models/ExecutionResult';
import { PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Panel } from 'react-resizable-panels';
import { ResultsPanelContainer } from '../components/ResultsPanel/container';
import { editor } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { PanelSeparatorSvg } from '../assets/svgs/PanelSeparator';
import { CodePlaygroundContext } from '../contexts/CodePlayground';
import { PlaygroundCodeEditorPanelContainer } from '../components/PlaygroundCodeEditorPanel/container';
import { PlaygroundMetadataPanelContainer } from '../components/PlaygroundMetadataPanel/container';
import { ISemanticModelMetadata } from '../models/SemanticModel';
import { useGetSemanticModels } from '../hooks/useGetSemanticModels';
import { useGetUserPlaygrounds } from '../hooks/useGetUserPlaygrounds';
import { PlaygroundDetails } from '../models/Playground';
import { runPlaygroundCode } from '../services/playgroundService';

export const CodePlayground: React.FC = () => {
  const [activeDataModelMetadata, setActiveDataModelMetadata] =
    useState<ISemanticModelMetadata>({
      modelName: 'Contoso',
      modelId: '123',
      tables: [],
    });
  const [selectedTable, setSelectedModel] = useState<string>('Contoso');
  const [codeInput, setCodeInput] = useState('');
  const [results, setResults] = useState<IPlaygroundExecutionResult | null>(null);
  const { user } = useAuth();
  const monacoRef = useRef<Monaco | null>(null);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const { userCodePlaygrounds: playgrounds, refreshPlaygrounds } = useGetUserPlaygrounds();
  const { playgroundId } = useParams();
  const [currentPlayground, setCurrentPlayground] = useState<PlaygroundDetails | null>(null);

  useEffect(() => {
    const loadData = async () => {
      if (playgroundId) {
        setCurrentPlayground(playgrounds.find((p) => p.id === Number(playgroundId)) || null);
        // Update starting code input if any had been saved before
        if (currentPlayground?.query_content) {
          setCodeInput(currentPlayground.query_content);
        }
      } else {
        console.error('No playground ID provided');
      }
    };

    loadData();
  }, [user, playgrounds, playgroundId]);


  const handleRun = async () => {
    const authToken = user ? await user.getIdToken() : null;
    if (!authToken || !currentPlayground) {
      return;
    }
    const codeRunResult = await runPlaygroundCode(
      authToken,
      currentPlayground.id,
      codeInput,
    );
    setResults(codeRunResult);
  };

  if (!currentPlayground) {
    return <div>Loading...</div>;
  }

  return (
    <CodePlaygroundContext.Provider
      value={{
        currentPlayground,
        codeInput,
        setCodeInput,
        results,
        editorRef,
        monacoRef,
      }}
    >
      <PanelGroup
        direction="horizontal"
        className="w-[calc(100vw-152px)]"
        style={{
          display: 'flex',
          height: '100vh',
          padding: '1rem',
          width: 'calc(100vw-120px)',
        }}
      >
        <Panel defaultSize={50} minSize={25}>
          <PlaygroundMetadataPanelContainer />
        </Panel>
        <PanelResizeHandle
          className="flex justify-center items-center"
          style={{ width: '20px' }}
        >
          <PanelSeparatorSvg direction={'vertical'} />
        </PanelResizeHandle>
        <Panel
          defaultSize={50}
          minSize={45}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <PanelGroup
            direction="vertical"
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Panel defaultSize={60} minSize={50}>
              <PlaygroundCodeEditorPanelContainer handleRun={handleRun} />
            </Panel>
            <PanelResizeHandle
              className="flex justify-center items-center"
              style={{ height: '20px' }}
            >
              <PanelSeparatorSvg direction={'horizontal'} />
            </PanelResizeHandle>
            <Panel defaultSize={40} minSize={20}>
              <ResultsPanelContainer results={results} />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </CodePlaygroundContext.Provider>
  );
};
