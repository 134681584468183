import React from 'react';

interface IPanelSepratorProps {
  direction: 'vertical' | 'horizontal';
}

export const PanelSeparatorSvg: React.FC<IPanelSepratorProps> = ({
  direction,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="3"
      fill="none"
      className={direction === 'vertical' ? 'rotate-90' : ''}
    >
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
      <circle
        cx="9.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
      <circle
        cx="17.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
      <circle
        cx="25.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
      <circle
        cx="33.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
      <circle
        cx="41.5"
        cy="1.5"
        r="1.5"
        fill="currentColor"
        opacity="0.4"
      ></circle>
    </svg>
  );
};
