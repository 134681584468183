import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

interface IAuthComponent {
  isAuthenticated: boolean;
  handleSignin: () => Promise<void>;
  handleSignout: () => Promise<void>;
}
const AuthComponent: React.FC<IAuthComponent> = ({
  isAuthenticated,
  handleSignin,
  handleSignout,
}) => {
  return (
    <div
      className="fixed bottom-4 left-4 rounded-lg p-2 bg-primary text-white tooltip"
      data-tooltip="Sign in with Google"
    >
      <button
        className="btn btn-ghost flex items-center justify-center"
        onClick={isAuthenticated ? handleSignout : handleSignin}
      >
        {isAuthenticated ? (
          <svg
            fill="#000000"
            viewBox="0 0 24 24"
            id="sign-out-2"
            data-name="Flat Line"
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <polyline
                id="primary"
                points="18 9 21 12 18 15"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeWidth: 2,
                }}
              ></polyline>
              <line
                id="primary-2"
                data-name="primary"
                x1="21"
                y1="12"
                x2="7"
                y2="12"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeWidth: 2,
                }}
              ></line>
              <path
                id="primary-3"
                data-name="primary"
                d="M14,16v3a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V5A1,1,0,0,1,4,4h9a1,1,0,0,1,1,1V8"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeWidth: 2,
                }}
              ></path>
            </g>
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 19"
          >
            <path
              fillRule="evenodd"
              d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>
    </div>
  );
  // return isAuthenticated ? (
  //   <div className="p-4">
  //     <button
  //       onClick={handleSignout}
  //       type="button"
  //       className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
  //     >
  //       Sign Out
  //     </button>
  //   </div>
  // ) : (
  //   <div className="p-4">
  //     <button
  //       onClick={handleSignin}
  //       type="button"
  //       className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
  //     >
  //       &#127881; Why we built this
  //     </button>
  //   </div>
  // );
};

export default AuthComponent;
