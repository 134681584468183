import { useState, useEffect } from 'react';
import { useAuth } from '../auth/authContext';
import { ICourseInfo } from '../models/Courses';
import { getAllCourses } from '../services/courseService';

export const useGetAllCourses = () => {
  const [allKnownCourses, setKnownCourses] = useState<ICourseInfo[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const courseQueryResult = await getAllCourses();
      setKnownCourses(courseQueryResult);
    };

    loadData();
  }, []);

  return allKnownCourses;
};
